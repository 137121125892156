'use client';
import { FunctionComponent, useMemo } from 'react';
import Container from '../../../../components/Container';
import Link from 'next/link';
import Image from 'next/image';
import { CourseCarousel } from '../../../../components/CourseCarousel';
import styles from './styles.module.scss';
// @ts-ignore This fails type checking in CI because it's in the public folder
import NotFoundOwl from '../../../../public/404-owl.png';
import { useEducatorProfileLinks } from '../../../../hooks/useEducatorProfileLinks';
import { Educator, IAm } from '@shared/types';
import { PublicEducator } from '@cademy-package/sdk-component-types';
import { useIam } from '../../../../services/IAm/hooks';

type CourseNotFoundProps = {
    educatorId: Educator['_id'] | PublicEducator['_id'];
    educatorName: Educator['name'] | PublicEducator['name'];
    educatorSlug: Educator['slug'] | PublicEducator['slug'];
};

const LoginChallengeListItem: FunctionComponent<{ iam: IAm }> = ({ iam }) => {
    if (iam.authenticated) {
        return (
            <li>
                You may be trying to access an order from the wrong account, try to{' '}
                <Link
                    href={`/logout?redirect=${encodeURIComponent(`${process.env.NEXT_PUBLIC_MARKETPLACE_URL}/login?redirect=${window.location.href}`)}`}
                >
                    log in as a different user
                </Link>
                .
            </li>
        );
    }
    return (
        <li>
            If you already have an active order for this course, you will need to{' '}
            <Link href={`/login?redirect=${encodeURIComponent(window.location.href)}`}>log in</Link>{' '}
            to view the details page.
        </li>
    );
};

export const CourseNotFound: FunctionComponent<CourseNotFoundProps> = ({
    educatorId,
    educatorName,
    educatorSlug,
}) => {
    const { coursesLink } = useEducatorProfileLinks(educatorSlug);
    const { iam } = useIam();

    return (
        <Container>
            <div className={styles.innerContainer}>
                <div className={styles.infoSection}>
                    <h1>Course Not Found</h1>
                    <h3>The course is not published or does not exist.</h3>
                    <ul>
                        <li>Check the URL for typos.</li>
                        <li>The page URL may have been changed.</li>
                        <LoginChallengeListItem iam={iam} />
                        <li>
                            You can check{' '}
                            <Link href={coursesLink}>
                                some other great offerings from {educatorName}
                            </Link>
                            .
                        </li>
                        <li>
                            If you there is a technical error, you can{' '}
                            <a href="mailto:support@cademy.io">reach out to our support team</a>.
                        </li>
                    </ul>
                </div>
                <div className={styles.imageContainer}>
                    <Image src={NotFoundOwl} alt="oops" width={250} height={250} />
                </div>
                <div className={styles.carouselSection}>
                    <CourseCarousel
                        heading={`More from ${educatorName}`}
                        filters={{ educator_ref: [educatorId] }}
                        endCardText={`View all listings by ${educatorName}`}
                        endCardLink={coursesLink}
                        name={`More listings from ${educatorName}`}
                    />
                </div>
            </div>
        </Container>
    );
};
