'use client';

import { Course } from '@shared/types';
import { useCallback, useMemo, useState } from 'react';
import { CademyAPI } from '../../services/CademyAPI';
import { useIam } from '../../services/IAm/hooks';
import LoginModal from '../../components/LoginModal';

export type UseSavedCourseOptions = {
    courseId: Course['_id'];
    saved: boolean;
};

export const useSavedCourse = ({ courseId, saved: initialSaved }: UseSavedCourseOptions) => {
    const [optimistcallySaved, setOptimistcallySaved] = useState<boolean | null>(null);
    const { iam, iamReady } = useIam();
    const [showLoginModal, setShowLoginModal] = useState<boolean>(false);

    const onSave = useCallback(async () => {
        if (iam.authenticated) {
            setOptimistcallySaved(true);
            await CademyAPI('AddCourseToSavedCourses')({ body: { courseId } });
        } else {
            setShowLoginModal(true);
        }
    }, [courseId, iam]);

    const onUnsave = useCallback(async () => {
        setOptimistcallySaved(false);

        await CademyAPI('RemoveCourseFromSavedCourses')({ urlParams: { courseId } });
    }, [courseId]);

    const onClose = useCallback(() => {
        setShowLoginModal(false);
    }, []);

    const onAuthenticated = useCallback(async () => {
        setShowLoginModal(false);
        setOptimistcallySaved(true);

        await CademyAPI('AddCourseToSavedCourses')({ body: { courseId } });
    }, [courseId]);

    const loginModal = useMemo(() => {
        return (
            <LoginModal
                visible={showLoginModal}
                onClose={onClose}
                onAuthenticated={onAuthenticated}
                message="Log in to save this course"
            />
        );
    }, [showLoginModal, onClose, onAuthenticated]);

    return {
        onSave,
        onUnsave,
        saved: optimistcallySaved !== null ? optimistcallySaved : initialSaved,
        loginModal,
    };
};
